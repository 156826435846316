import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import service from '../../../services/service';
import './OtpForm.less';

const OTPForm = ({ email, setUser, setToken, closeModal }) => {
  const [otp, setOtp] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval = null;
    if (resendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      if (timer === 0) setResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [resendDisabled, timer]);


  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setDisableBtn(true);
    try {
      const response = await service.verifyOtp(email, otp);
      setUser(response);
      setToken(response.access_token);
      localStorage.setItem('User', JSON.stringify(response));
      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('refresh_token', response.refresh_token);
      toast.success('Login successful');
      closeModal();
    } catch (error) {
      toast.error(error.response?.data?.message || 'OTP verification failed');
      setDisableBtn(false);
    }
  };

  const handleResendOtp = async () => {
    setResendDisabled(true);
    setTimer(60);
    try {
      await service.resendOtp(email);
      toast.success('A new OTP has been sent to your email.');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to resend OTP');
    }
    setTimeout(() => setResendDisabled(false), 60000);
  };

  return (
    <div className="otp-form-container">
      <div className="otp-form">
        <h2>Verify OTP</h2>
        <form onSubmit={handleVerifyOtp}>
          <div className="form-group">
            <label htmlFor="otp">Enter OTP</label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="OTP"
              required
            />
          </div>
          <button type="submit" disabled={disableBtn} className="btn-submit">
            Verify OTP
          </button>
        </form>
        <div className="resend-section">
          <button
            type="button"
            className="btn-resend"
            onClick={handleResendOtp}
            disabled={resendDisabled}
          >
            Resend OTP
          </button>
          {resendDisabled && (
            <span className="timer">
                <div className="circle">{timer}s</div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default OTPForm;
