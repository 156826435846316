import React from 'react';
import { differenceInDays, parse } from 'date-fns';

type WaccRange = {
  costOfDebt: number;
  weightOfDebt: number;
  costOfEquity: number;
  weightOfEquity: number;
};

type RiskPremiums = {
  location: number;
  tenantProfile: number;
  wale: number;
  buildingLife: number;
  vacancy: number;
  propertyType: number;
  esgCompliance: number;
};

type WACCResults = {
  calculatedWacc: number;
  totalRiskPremium: number;
  waccDiscountRate: number;
};

const compareCompanies = [
  {
    name: { key: 'Name', value: 'Embassy Office Parks REIT Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 0.61 },
    leveredBeta: { key: 'Levered Beta', value: 0.1 },
  },
  {
    name: { key: 'Name', value: 'Brookfield India Real Estate Trust Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 1.18 },
    leveredBeta: { key: 'Levered Beta', value: 0.21 },
  },
  {
    name: { key: 'Name', value: 'Mindspace Business Parks REIT Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 0.21 },
    leveredBeta: { key: 'Levered Beta', value: 0.09 },
  },
  {
    name: { key: 'Name', value: 'Brigade Enterprises Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 1.46 },
    leveredBeta: { key: 'Levered Beta', value: 1.25 },
  },
  {
    name: { key: 'Name', value: 'DLF Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 0.12 },
    leveredBeta: { key: 'Levered Beta', value: 1.53 },
  },
  {
    name: { key: 'Name', value: 'Godrej Properties Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 1.07 },
    leveredBeta: { key: 'Levered Beta', value: 1.55 },
  },
  {
    name: { key: 'Name', value: 'Prestige Estates Projects Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 1.02 },
    leveredBeta: { key: 'Levered Beta', value: 1.36 },
  },
  {
    name: { key: 'Name', value: 'Nexus Select Trust Ltd.' },
    taxRate: { key: 'Tax Rate', value: 0.25 },
    debtEquity: { key: 'Debt / Equity', value: 0.15 },
    leveredBeta: { key: 'Levered Beta', value: 0.16 },
  },
]; // Data is Fetch from Api Integration SEBI

const configuration = {
  tragetTaxRate: { key: 'Tax Rate', value: 0.25 },
  targetDebtEquityRatio: { key: 'Target Debt / equity ratio', value: 0.5 },
  rateOfInterestOnDebt: { key: 'Rate of Interest on debt (% p.a.)', value: 0.085 },
  taxRateOnDebt: { key: 'Tax Rate (%)', value: 0.25 },
  riskFreeReturn: { key: 'Risk-Free Return', value: 0.0701 },
}; // Application inside configuration

const marketIndices = [
  {
    name: { key: 'Name', value: 'Nifty Realty' },
    startDate: { key: 'Start Date', value: '4/1/2014' },
    valuationDate: { key: 'Valuation Date', value: '3/31/2024' },
    startDateMarketValue: { key: 'Market Value as on Start Date', value: 190 },
    valuationDateMarketValue: { key: 'Market Value as on Valuation Date', value: 900 },
  },
]; // Data is Fetch from Api Integration SEBI

const locationOptions = [
  { label: 'Mumbai', value: 0.5 },
  { label: 'Bengaluru', value: 0.5 },
  { label: 'Chennai', value: 0.5 },
  { label: 'Hyderabad', value: 0.75 },
  { label: 'Pune', value: 0.5 },
  { label: 'NCR', value: 0.75 },
  { label: 'Ahmedabad', value: 0.5 },
  { label: 'Amritsar', value: 0.5 },
  { label: 'Bhubaneshwar', value: 0.5 },
  { label: 'Chandigarh', value: 0.5 },
  { label: 'Delhi', value: 0.75 },
  { label: 'Gurugram', value: 0.75 },
  { label: 'Indore', value: 0.5 },
  { label: 'Kolkata', value: 0.5 },
  { label: 'Mangaluru', value: 0.5 },
  { label: 'Mysuru', value: 0.5 },
  { label: 'Noida', value: 0.75 },
  { label: 'Udaipur', value: 0.5 },
  { label: 'Navi Mumbai', value: 0.5 },
  { label: 'Karnataka', value: 0.5 },
];

export const calculateAverageAndMedian = () => {
  //sum up all the values
  const taxRates = compareCompanies.map((company) => company.taxRate.value);

  const debtEquity = compareCompanies.map((company) => company.debtEquity.value);
  const leveredBeta = compareCompanies.map((company) => company.leveredBeta.value);
  const unleveredBeta = compareCompanies.map((company) => {
    const { taxRate, debtEquity, leveredBeta } = company;
    return leveredBeta.value / (1 + (1 - taxRate.value) * debtEquity.value);
  });

  //calc avg
  const averageTaxRate = taxRates.reduce((acc, rate) => acc + rate, 0) / taxRates.length;
  const avgDebtEquity = debtEquity.reduce((acc, rate) => acc + rate, 0) / debtEquity.length;
  const avgLeveredBeta = leveredBeta.reduce((acc, rate) => acc + rate, 0) / leveredBeta.length;
  const avgUnleveredBeta = unleveredBeta.reduce((acc, rate) => acc + rate, 0) / unleveredBeta.length;

  //calc sorted
  const sortedTaxRates = [...taxRates].sort((a, b) => a - b);
  const sorteddebtEquity = [...debtEquity].sort((a, b) => a - b);
  const sortedleveredBeta = [...leveredBeta].sort((a, b) => a - b);
  const sortedunleveredBeta = [...unleveredBeta].sort((a, b) => a - b);

  //calc mid
  const midTaxRate = Math.floor(sortedTaxRates.length / 2);
  const middebtEquity = Math.floor(sorteddebtEquity.length / 2);
  const midleveredBeta = Math.floor(sortedleveredBeta.length / 2);
  const midunleveredBeta = Math.floor(sortedunleveredBeta.length / 2);

  //calc medain
  const medianTaxRate =
    sortedTaxRates.length % 2 !== 0
      ? sortedTaxRates[midTaxRate]
      : (sortedTaxRates[midTaxRate - 1] + sortedTaxRates[midTaxRate]) / 2;
  const medianDebtEquity =
    sorteddebtEquity.length % 2 !== 0
      ? sorteddebtEquity[middebtEquity]
      : (sorteddebtEquity[midTaxRate - 1] + sorteddebtEquity[middebtEquity]) / 2;
  const medianLeveredBeta =
    sortedleveredBeta.length % 2 !== 0
      ? sortedleveredBeta[midTaxRate]
      : (sortedleveredBeta[midleveredBeta - 1] + sortedleveredBeta[midleveredBeta]) / 2;
  const medianUnleveredBeta =
    sortedunleveredBeta.length % 2 !== 0
      ? sortedunleveredBeta[midunleveredBeta]
      : (sortedunleveredBeta[midunleveredBeta - 1] + sortedunleveredBeta[midunleveredBeta]) / 2;

  const compareAvgMedian = {
    Average: { averageTaxRate, avgDebtEquity, avgLeveredBeta, avgUnleveredBeta },
    median: { medianTaxRate, medianDebtEquity, medianLeveredBeta, medianUnleveredBeta },
  };

  return compareAvgMedian;
};

//Calculation of levered beta
export const calculateLeveredBeta = () => {
  const unleveredBeta = calculateAverageAndMedian().median.medianUnleveredBeta;
  const targetDebtEquityRatio = configuration.targetDebtEquityRatio.value;
  const tragetTaxRate = configuration.tragetTaxRate.value;
  const leveredBeta = unleveredBeta * (1 + (1 - tragetTaxRate) * targetDebtEquityRatio);
  return leveredBeta;
};

// 1.Cost of Debt
export const calculateCostOfDebt = () => {
  const taxRateOnDebt = configuration.taxRateOnDebt.value;
  const rateOfInterestOnDebt = configuration.rateOfInterestOnDebt.value;
  const postTaxRate = rateOfInterestOnDebt * (1 - taxRateOnDebt);
  return postTaxRate * 100;
};

//Calculation of return on market
const calculateYearFrac = (startDate: string, valuationDate: string) => {
  const start = parse(startDate, 'M/d/yyyy', new Date());
  const end = parse(valuationDate, 'M/d/yyyy', new Date());
  return differenceInDays(end, start) / 365.25;
};

const calculateTotal = (startDateMarketValue: number, valuationDateMarketValue: number, year: number) => {
  return (valuationDateMarketValue / startDateMarketValue) ** (1 / 10) - 1;
};

export const calculateReturnOnMarket = () => {
  let data = marketIndices.map((company) => {
    const year = calculateYearFrac(company.startDate.value, company.valuationDate.value);
    const total = calculateTotal(
      company.startDateMarketValue.value,
      company.valuationDateMarketValue.value,
      parseFloat(year.toFixed(2)),
    );
    return {
      ...company,
      year: { key: 'Year', value: year },
      total: { key: 'Total', value: total },
    };
  });
  const yearCAGR = { carg: data[0].total };
  return yearCAGR;
};

// 2. Cost of Equity
export const calculateCostOfEquity = () => {
  const riskFreeReturn = configuration.riskFreeReturn.value;
  const leveredBeta = calculateLeveredBeta();
  const returnOnMarket = calculateReturnOnMarket().carg.value;
  const costofEquity = riskFreeReturn + leveredBeta * (returnOnMarket - riskFreeReturn);
  return costofEquity * 100;
};

//Calculation of Net Present Value (NPV)
export const calculateXNPV = (rate: number, values: number[], dates: string[]): number => {
  const parsedDates = dates.slice(1).map((date) => new Date(date));
  const baseDate = new Date(dates[1]);

  return values.reduce((acc, value, index) => {
    const date = parsedDates[index];
    const days = (date.getTime() - baseDate.getTime()) / (1000 * 60 * 60 * 24);
    const years = days / 365;
    const presentValue = value / Math.pow(1 + rate / 100, years);
    return acc + presentValue;
  }, 0);
};

const waleOptions = [
  { label: 'More than 5', value: 0.25 },
  { label: 'Between 2-5', value: 0.5 },
  { label: 'Less than 2', value: 0.75 },
];

const getWaleValue = (value: number): number => {
  if (value < 2) {
    return 0.75;
  } else if (value >= 2 && value < 5) {
    return 0.5;
  } else if (value >= 5) {
    return 0.25;
  } else {
    return 0.75;
  }
};

const getEsgCompliance = (value: number): number => {
  if (value <= 0 && value > -0.25) {
    return 0;
  } else if (value <= -0.25 && value > -0.5) {
    return -0.25;
  } else if (value <= -0.5) {
    return -0.5;
  }
  return 0;
};

const getBuildingAge = (value: number): number => {
  if (value >= 0 && value < 10) {
    return 0.25;
  } else if (value >= 10 && value < 20) {
    return 0.75;
  } else if (value >= 20) {
    return 1;
  }
  return 0.25;
};

const getVacancy = (value: number): number => {
  if (value == null && value == undefined && value < 10) {
    return 0.25;
  } else if (value >= 10 && value < 25) {
    return 0.5;
  } else if (value >= 25) {
    return 1;
  }
  return 0.25;
};

export const initialcalculateWACC = (
  location: string,
  waleValue: number,
  esgComplianceValue: number = 0,
  buildingAge: number = 0,
  vacancy: number = 0,
) => {
  const waccRange: WaccRange = {
    costOfDebt: calculateCostOfDebt(),
    weightOfDebt: 50,
    costOfEquity: calculateCostOfEquity(),
    weightOfEquity: 50,
  };

  const riskPremiums: RiskPremiums = {
    location: Number(locationOptions.find((city) => city.label === location)?.value || 0),
    tenantProfile: 0.25,
    wale: getWaleValue(waleValue),
    buildingLife: getBuildingAge(buildingAge),
    vacancy: getVacancy(vacancy),
    propertyType: 0.1,
    esgCompliance: getEsgCompliance(esgComplianceValue),
  };

  let _calculatedWacc =
    (waccRange.costOfDebt * waccRange.weightOfDebt + waccRange.costOfEquity * waccRange.weightOfEquity) / 100;
  let _totalRiskPermium = parseFloat(
    (
      riskPremiums.location +
      riskPremiums.tenantProfile +
      riskPremiums.wale +
      riskPremiums.buildingLife +
      riskPremiums.vacancy +
      riskPremiums.propertyType +
      riskPremiums.esgCompliance
    ).toFixed(2),
  );
  let _waccDiscountRate = _calculatedWacc + _totalRiskPermium;

  const WACCResults: WACCResults = {
    calculatedWacc: _calculatedWacc,
    totalRiskPremium: _totalRiskPermium,
    waccDiscountRate: _waccDiscountRate,
  };

  return { waccRange, riskPremiums, WACCResults };
};
