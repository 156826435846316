import axios from 'axios';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser_] = useState(JSON.parse(localStorage.getItem('User')));
  const [token, setToken_] = useState(localStorage.getItem('access_token'));

  const setToken = (newToken) => {
    setToken_(newToken);
  };
  const setUser = (user) => {
    setUser_(user);
  };

  const logout = () => {
    setUser_(null);
    setToken_(null);
    localStorage.removeItem('User');
    localStorage.removeItem('access_token');
    localStorage.clear();
    sessionStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
  };

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      localStorage.setItem('access_token', token);
    } else {
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      user,
      setUser,
      logout,
    }),
    [token, user],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
