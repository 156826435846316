import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/auth-context';
import LoginForm from '../components/login-form';
import SignupForm from '../components/signup-form';
import Modal from './modal';
import service from '../services/service';

const login = () => {
  const [modalContent, setModalContent] = useState(null);
  const { token, setToken, setUser, logout } = useAuth();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }
  }, []);

  const openLogin = () => setModalContent('login');
  const openSignup = () => setModalContent('signup');
  const closeModal = () => setModalContent(null);

  const handleLogout = async (e) => {
    e.preventDefault();
    await service.logout();
    logout();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <div>
      {token ? (
        <button className='login-close' onClick={handleLogout}>
          Log out
        </button>
      ) : (
        <>
          <button className='login-button' onClick={openLogin}>
            Login
          </button>
          <Modal isOpen={modalContent !== null} onClose={closeModal}>
            {modalContent === 'login' && <LoginForm onSwitch={openSignup} closeModal={closeModal} />}
            {modalContent === 'signup' && <SignupForm onSwitch={openLogin} closeModal={closeModal} />}
          </Modal>
        </>
      )}
    </div>
  );
};

export default login;
