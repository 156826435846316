import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import service from '../../../services/service';
import './ResetPassword.less';

const ResetPasswordForm = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }

    try {
      await service.resetPassword(token, password);
      toast.success('Password reset successfully!');
      navigate('/');
    } catch (error) {
      toast.error('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className='reset-container'>
      <div className='container'>
        <h1 className='header'>Reset Password</h1>
        <form onSubmit={handleSubmit} className='form'>
          <input
            type='password'
            placeholder='New Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className='input'
          />
          <input
            type='password'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className='input'
          />
          <button type='submit' className='button'>
            Reset Password
          </button>
        </form>
      </div>
      <div className='pseudo-div'></div>
    </div>
  );
};

export default ResetPasswordForm;
