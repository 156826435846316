import React, { createContext, useState, useContext, useEffect } from 'react';

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(() => {
    const savedProperty = localStorage.getItem('selectedProperty');
    return savedProperty ? JSON.parse(savedProperty) : [];
  });
  const [rows, setRows] = useState(() => {
    const savedRows = localStorage.getItem('dynamicDropdownRows');
    return savedRows ? JSON.parse(savedRows) : [{ cities: '', properties: '', propertiesOptions: [] }];
  });

  useEffect(() => {
    if (rows && rows.length > 0) {
      localStorage.setItem('dynamicDropdownRows', JSON.stringify(rows));
    }
  }, [rows]);

  useEffect(() => {
    if (selectedProperty) {
      localStorage.setItem('selectedProperty', JSON.stringify(selectedProperty));
    }
  }, [selectedProperty]);

  return (
    <SearchContext.Provider
      value={{
        data,
        setData,
        cities,
        setCities,
        selectedCity,
        setSelectedCity,
        properties,
        setProperties,
        selectedProperty,
        setSelectedProperty,
        rows,
        setRows,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

const useData = () => {
  const { data, setData } = useSearchContext();
  return [data, setData];
};

const useCities = () => {
  const { cities, setCities } = useSearchContext();
  return [cities, setCities];
};

const useSelectedCity = () => {
  const { selectedCity, setSelectedCity } = useSearchContext();
  return [selectedCity, setSelectedCity];
};

const useProperties = () => {
  const { properties, setProperties } = useSearchContext();
  return [properties, setProperties];
};

const useSelectedProperty = () => {
  const { selectedProperty, setSelectedProperty } = useSearchContext();
  return [selectedProperty, setSelectedProperty];
};

const useRows = () => {
  const { rows, setRows } = useSearchContext();
  return [rows, setRows];
};

export { SearchProvider, useRows, useData, useCities, useSelectedCity, useProperties, useSelectedProperty };
