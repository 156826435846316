import axios from 'axios';
import _ from 'lodash';
import axiosInstance from './axios/axios-instance';
//import * as Cookies from 'js-cookie';

const register = (name, mail, password, recaptchatoken) => {
  return axios.post(`${process.env.API_URL}/auth/register`, { name, mail, password, recaptchatoken });
};

const login = async (name, password) => {
  //const csrfToken = Cookies.get('csrfToken');
  const response = await axios.post(
    `${process.env.API_URL}/auth/login`,
    { name, password },
    //,{ headers: { 'X-XSRF-TOKEN': csrfToken,},}
  );
  // if (response.data.mail) {
  //   localStorage.clear();
  //   localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
  //   localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token));
  //   localStorage.setItem('User', JSON.stringify(response.data))
  // }
  return response.data;
};

const refreshToken = async () => {
  const user = JSON.parse(localStorage.getItem('User'));
  if (user && user.refresh_token) {
    const response = await axios.post(`${process.env.API_URL}/auth/refresh-token`, {
      refreshToken: user.refresh_token,
    });
    if (response.data.access_token) {
      user.access_token = response.data.access_token;
      localStorage.setItem('access_token', JSON.stringify(user));
    }
    return response.data.access_token;
  }
  return null;
};

const reitPorfilo = async (id) => {
  try {
    const response = await axiosInstance.get(`/reit/${id}`);
    if (!_.isEmpty(response.data)) {
      return response;
    }
  } catch (error) {
    return null;
  }
};

const comapreCards = async () => {
  try {
    const response = await axiosInstance.get(`/reit/compare/cards`);
    if (!_.isEmpty(response.data)) {
      return response;
    }
  } catch (error) {
    return null;
  }
};

const comapreReit = async () => {
  const user = JSON.parse(localStorage.getItem('User'));
  if (user && user.refresh_token) {
    try {
      const response = await axiosInstance.get(`/reit/compare/data`);
      if (!_.isEmpty(response.data)) {
        return response;
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};
const getAdjacentById = async (Id) => {
  const user = JSON.parse(localStorage.getItem('User'));
  if (user && user.refresh_token) {
    try {
      const response = await axiosInstance.get(`/reit/adjct/id?id=${Id}`);
      if (!_.isEmpty(response.data)) {
        return response;
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};

const getAdjacentByName = async (value) => {
  const user = JSON.parse(localStorage.getItem('User'));
  if (user && user.refresh_token) {
    try {
      const response = await axiosInstance.get(`/adjacent/${value}`);
      if (!_.isEmpty(response.data)) {
        return response;
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};

const compareAdjacent = async (value) => {
  const user = JSON.parse(localStorage.getItem('User'));
  if (user && user.refresh_token) {
    try {
      const response = await axiosInstance.get(`/adjacent/findbyname/name?name=${value}`);
      if (!_.isEmpty(response.data)) {
        return response;
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};

const fetchCities = async () => {
  try {
    //const response = await axiosInstance.get(`/reit/search/cities`);
    const response = await axiosInstance.get(`/adjacent/cities`);
    if (!_.isEmpty(response.data)) {
      return response.data;
    }
  } catch (error) {
    console.error('Failed to fetch cities:', error);
    return null;
  }
};

const getCards = async () => {
  try {
    const response = await axiosInstance.get(`/reit/`);
    if (!_.isEmpty(response.data)) {
      return response.data;
    }
  } catch (error) {
    console.error('Failed to fetch cities:', error);
    return null;
  }
};

const valautionAdjacnet = async (value) => {
  const user = JSON.parse(localStorage.getItem('User'));
  if (user && user.refresh_token) {
    try {
      const response = await axiosInstance.get(`/adjacent/valuation?id=${value}`);
      if (!_.isEmpty(response.data)) {
        return response;
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};

const logout = async () => {
  await axios.get(`${process.env.API_URL}/auth/logout`);
};

const getCsrfToken = async () => {
  try {
    const response = await axios.get(`${process.env.API_URL}/auth/csrf/token`);
    const data = response.data;
    Cookies.set('csrfToken', data.csrfToken);
    return data.csrfToken;
  } catch (error) {
    console.error('Failed to fetch CSRF token:', error);
    return null;
  }
};

const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(`${process.env.API_URL}/user/contact`, formData);
    return response.data;
  } catch (error) {
    console.error('Failed to submit contact form:', error);
    throw error;
  }
};

const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${process.env.API_URL}/auth/forgot-password`, { email });
    return response.data;
  } catch (error) {
    console.error('Failed to send password reset request:', error);
    throw error;
  }
};

const resetPassword = async (token, password) => {
  const response = await axios.post(`${process.env.API_URL}/auth/reset-password`, { token, password });
  return response;
};

const verifyOtp = async (email, otp) => {
  try {
    const response = await axios.post(`${process.env.API_URL}/auth/verify-otp`, { email, otp });
    return response.data;
  } catch (error) {
    console.error('Failed to verify OTP:', error);
    throw error;
  }
};

const resendOtp = async (email) => {
  const response = await axios.post(`${env.API_URL}/auth/resend-otp`, { email });
  return response.data;
};

// const fetchCityRowData = aync () =>{
//   try{
//     const response = await axiosInstance.get('');
//     if(!_.isEmpty(response.data)){
//       return response.data;
//     }
//   }catch(error){
//     console.log('failed to fetch the data: ',error);
//     return null;
//   }
// };

export default {
  register,
  resendOtp,
  verifyOtp,
  submitContactForm,
  resetPassword,
  requestPasswordReset,
  login,
  refreshToken,
  reitPorfilo,
  comapreCards,
  comapreReit,
  getAdjacentById,
  getAdjacentByName,
  compareAdjacent,
  fetchCities,
  getCards,
  valautionAdjacnet,
  logout,
  getCsrfToken /* fetchCityRowData */,
};
