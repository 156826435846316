import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data, dimensions, label }) => {
  const ref = useRef();
  const tooltipRef = useRef();
  const customColors = ["#0FDCD2", "#14324E", "#A5A5A5", "#416D91", "#5B9AD5"];

  useEffect(() => {
    // Adjust dimensions for smaller screens
    const adjustedDimensions = window.innerWidth < 767
      ? { width: 250, height: 250 }
      : dimensions;

    const radius = Math.min(adjustedDimensions.width, adjustedDimensions.height) / 2;

    const svg = d3.select(ref.current)
      .attr("width", adjustedDimensions.width)
      .attr("height", adjustedDimensions.height)
      .attr("viewBox", `0 0 ${adjustedDimensions.width} ${adjustedDimensions.height}`)  // Added viewBox to enable scaling
      .selectAll("*")
      .remove(); // Clear previous chart

    const container = d3.select(ref.current)
      .append("g")
      .attr("transform", `translate(${adjustedDimensions.width / 2}, ${adjustedDimensions.height / 2})`);

    const color = d3.scaleOrdinal(customColors);

    const pie = d3.pie()
      .value(d => d.value);

    const arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(0);

    const arcs = container.selectAll(".arc")
      .data(pie(data))
      .enter().append("g")
      .attr("class", "arc");

    arcs.append("path")
      .attr("d", d => arc(d))
      .attr("fill", (d, i) => color(i))
      .on("mouseover", function (event, d) {
        d3.select(tooltipRef.current)
          .style("display", "block")
          .html(`${d.data.label}: ${d.data.value}`);
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", d3.arc().innerRadius(0).outerRadius(radius + 10));
      })
      .on("mousemove", function (event) {
        const [x, y] = d3.pointer(event);
        const tooltip = d3.select(tooltipRef.current);
        tooltip.style("left", `${x + 300}px`)
          .style("top", `${y + 120}px`);
      })
      .on("mouseout", function () {
        d3.select(tooltipRef.current)
          .style("display", "none");
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", arc);
      });
  }, [data, dimensions]);

  return (
    <div style={{
      display: "flex", 
      flexDirection: window.innerWidth < 767 ? "column" : "row", // Adjust layout for mobile
      justifyContent: "center",
      alignItems: "center", 
      position: "relative", 
      padding: "20px"
    }}>
      <svg ref={ref}></svg>
      <div style={{
        display: "flex",
        flexDirection: "column", 
        alignItems: "flex-start", 
        marginTop: window.innerWidth < 767 ? "20px" : "0", // Adjust margin for mobile view
      }}>
        <div ref={tooltipRef} style={{
          display: 'flex',
          position: "absolute",
          backgroundColor: "#2fbeb7",
          fontFamily: "Gotham-Medium",
          fontSize: "14px",
          borderRadius: "8px",
          padding: "5px"
        }}></div>
        <div>
          {label && (
            <div style={{ marginBottom: "10px" }}>
              <span style={{ color: "#696969" }}>
                {label[0] + " : "}
              </span>
              <span style={{
                color: "#121212", 
                fontFamily: "Gotham-Medium", 
                whiteSpace: "pre-wrap", 
                display: "inline-flex"
              }}>
                {label[1]}
              </span>
            </div>
          )}
        </div>

        <table style={{
          marginTop: window.innerWidth < 767 ? "10px" : "20px", // Adjust table margin for mobile
          width: "100%",
          borderCollapse: "collapse"
        }}>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div style={{
                    display: "flex", 
                    alignItems: "center", 
                    fontSize: "16px",
                    padding: "5px 0"
                  }}>
                    <span style={{
                      borderRadius: '50px',
                      backgroundColor: customColors[index % customColors.length],
                      width: '14px', 
                      height: '14px', 
                      marginRight: '5px'
                    }}></span>
                    <span>
                      <span style={{ color: "#696969" }}>{item.label}: </span>
                      <span style={{
                        color: "#121212", 
                        fontFamily: "Gotham-Medium"
                      }}>
                        {item.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PieChart;
