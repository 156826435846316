import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useCities, useSelectedCity, useProperties, useSelectedProperty, useData, useRows } from '../../../../context/search-context';
import _ from 'lodash';
import { toast } from 'react-toastify';
import service from '../../../../services/service';
import { useLocation } from 'react-router-dom';

import './index.less';

const DynamicDropdownRow = ({ onData }) => {
    const location = useLocation();
    const [data, setData] = useData();
    const [cities, setCities] = useCities();
    const [selectedCity, setSelectedCity] = useSelectedCity();

    const [properties, setProperties] = useProperties();
    const [selectedProperties, setSelectedProperties] = useSelectedProperty();

    const [rows, setRows] = useRows();

    useEffect(() => {

        const fetchData = async () => {
          try {
            const response = await service.fetchCities();
            if (_.isEmpty(response)) {
              throw new Error('HTTP error! status: No response');
            }
            setData(response);
            const cityOptions = response.map((location) => ({
              value: location.location,
              label: location.location,
            })).sort((a, b) => a.label.localeCompare(b.label));
            setCities(cityOptions);
          } catch (error) {
            console.error('Failed to fetch data:', error);
          }
        };
    
        fetchData();
      }, [setData, setCities, location]);

    // const getSelectedPropertyValues = (rows) => {
    //     return rows.map(row => row.properties.value).filter(value => value);
    // };

    const customStyles = { menu: base => ({ ...base, marginTop: 0,position: 'absolute', zIndex: 99 }) };

    useEffect(() => {
        const updatedRows = [...rows];
        onData(updatedRows.map(row => row.properties?.value).filter(Boolean));
    },[]);

    useEffect(() => {
        if (location.pathname === '/compare-adjacent') {
          const savedRows = localStorage.getItem('dynamicDropdownRows');
          if (savedRows) {
            try {
              const parsedRows = JSON.parse(savedRows);
              if (Array.isArray(parsedRows)) {
                setRows(parsedRows);
              } else {
                console.warn('Invalid data in localStorage for dynamicDropdownRows');
                setRows([{ cities: '', properties: '', propertiesOptions: [] }]);
              }
            } catch (error) {
              console.error('Error parsing dynamicDropdownRows from localStorage:', error);
              setRows([{ cities: '', properties: '', propertiesOptions: [] }]);
            }
          } else {
            setRows([{ cities: '', properties: '', propertiesOptions: [] }]);
          }
        } else {
          setRows([{ cities: '', properties: '', propertiesOptions: [] }]);
        }
      }, [location.pathname]);

    //   useEffect(() => {
    //     if (Array.isArray(rows) && rows.length > 0) {
    //       localStorage.setItem('dynamicDropdownRows', JSON.stringify(rows));
    //     }
    //   }, [rows]);

    const handleInputChange = (type, e, index) => {
        const updatedRows = [...rows];
        updatedRows[index][type] = e;
        setRows(updatedRows);

        if (type === 'properties') {
            const updatedProperties = updatedRows.map(row => row.properties);
            setSelectedProperties(updatedProperties); // Sync properties context
        }
        onData(updatedRows.map(row => row.properties?.value).filter(Boolean));
    };

    const handleAddRow = () => {
        if (rows.length < 4) {
            const newRow = { cities: '', properties: '', propertiesOptions: [] };
            const newRows = [...rows, newRow];
            setRows(newRows);
        } else {
            toast.warn("You can only select a maximum of 4 properties.");
        }
    };

    const handleRemoveRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
        onData(newRows.map(row => row.properties?.value).filter(Boolean));
    };

    const handleCityChange = (selectedOption, index) => {
        const values = [...rows];
        const selectedLocation = _.find(data, { location: selectedOption.value });

        const propertyOptions = selectedLocation.cities
            .map((i) => ({
                value: i.name,
                label: i.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        values[index].cities = selectedOption;
        values[index].propertiesOptions = propertyOptions;
        values[index].properties = '';

        setRows(values);
    };

    return (
        <table className="dynamic-dropdown-table">
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <Select
                                options={cities}
                                value={row.cities}
                                onChange={(e) => handleCityChange(e, index)}
                                placeholder="Select City"
                                styles={customStyles}
                            />
                        </td>
                        <td>
                            <Select
                                name="properties"
                                options={row.propertiesOptions}
                                onChange={(e) => handleInputChange('properties', e, index)}
                                value={row.properties}
                                isDisabled={!row.cities}
                                placeholder="Select Property"
                                styles={customStyles}
                            />
                        </td>
                        <td>
                            {index !== 0 ? (
                                <button
                                    className="remove-row-btn"
                                    onClick={() => handleRemoveRow(index)}
                                >
                                    -
                                </button>
                            ) : (
                                <button
                                    className="add-row-btn"
                                    onClick={handleAddRow}
                                >
                                    +
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DynamicDropdownRow;