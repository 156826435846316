import React, { useState } from 'react';
import { toast } from 'react-toastify';
import service from '../../../services/service';
import './ForgotPassword.less';

const ForgotPassword = ({ onClose }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await service.requestPasswordReset(email);
      toast.success('Password reset email sent');
      onClose();
    } catch (error) {
      toast.error('Failed to send reset email');
    }
  };

  return (
    <div className='paper'>
      <h1 className='title'>Forgot Password</h1>
      <p className='description'>Enter your email to receive a password reset link.</p>
      <form onSubmit={handleSubmit} className='form'>
        <input
          type='email'
          placeholder='Email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className='input'
        />
        <button type='submit' className='btn primary'>
          Send Reset Link
        </button>
        <button type='button' onClick={onClose} className='btn secondary'>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
