import React from 'react';
import Searchbar from '../../shared/search-bar';
import { useNavigate } from 'react-router-dom';
import { useSelectedProperty, useSelectedCity } from '../../context/search-context';
import { toast } from 'react-toastify';
import './index.less';


const SearchAction = () => {
  const displaName = 'cmp-search-action';
  const [selectedProperty] = useSelectedProperty();
  const [selectedCity] = useSelectedCity();
  const navigate = useNavigate();

  const redirectTo = (path) => {
    if (!selectedCity || !selectedProperty) {
      toast.warn("Please Select City and Property", { position: "bottom-right", })
    }
    else {
      navigate(path);
      if(path === 'compare-adjacent'){
        navigate(0);
      }
    }
  };

  return (
    <div className={`${displaName}`}>
      <div className={`${displaName}__container`}>
        <div className={`${displaName}__container_title`}>
          <span className={`${displaName}__container_title-text`}>
            Compare or get valuation of any property with a simple search
          </span>
        </div>
        <div className={`${displaName}__container_control`}>
          <div>
            <Searchbar />
          </div>
          <div className={`${displaName}__container_control-btn`}>
            <button className={`${displaName}__container_control-btn-compare`} onClick={() => redirectTo('compare-adjacent')}>
              Compare
            </button>
            <button className={`${displaName}__container_control-btn-valuation`} onClick={() => redirectTo('evaluation')}>
              Valuation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchAction;