import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import service from '../../services/service';
import './ContactForm.less';
import Captcha from '../../shared/captcha';
import envelope from '../../assets/envelope.png';
import phone from '../../assets/phone.png';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: ''
  });
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = (name, email, phone, company, message) => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name cannot be empty';
    if (!email) newErrors.email = 'Invalid email';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) newErrors.email = 'Invalid email';
    if (!phone) newErrors.phone = 'Phone number cannot be empty';
    if (!company) newErrors.company = 'Company name cannot be empty';
    if (!message) newErrors.message = 'Message cannot be empty';
    return newErrors;
  };

  const reCaptchaTokenHandler = (event) => {
    setReCaptchaToken(event);
    const newErrors = validate(formData.name, formData.email, formData.phone, formData.company, formData.message);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (!event) {
      console.warn(event);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!reCaptchaToken) {
      toast.error('Please complete the reCAPTCHA');
      return;
    }
    try {
      await service.submitContactForm(formData);
      toast.success('Your message has been sent successfully!');
      window.location.reload();
      setFormData({ name: '', email: '', phone: '', company: '', message: '' });
      setReCaptchaToken(null);
      setIsButtonDisabled(true);
    } catch (error) {
      toast.error('Failed to send message. Please try again.');
    }
  };

  return (
    <div className="image-section">
      {/* <img src="../../assets/map-banner.png" alt="" className=''/> */}
        <div className='contact-form-container'>
          <div className='left-section'>
            <h4 className='content first-header'>CARE Analytics and Advisory Private Limited.</h4>
            <p className='content short-text'>Formerly known as CARE Risk Solutions Private Limited</p>
            <p className='content short-text'>wholly-owned subsidiary of CARE Ratings Limited </p>
            <p className='content'>CIN: U74210MH1999PTC118349</p>
            <h4 className='content'>Office No. 602, 6th Floor, Rustomjee Aspiree,</h4>
            <h4 className='content'>Off Eastern Express Highway,</h4>
            <h4 className='content'>Sion-E, Mumbai - 400 022.</h4>
            <div className='mail-contact'>
              <img className='contact-icon' src={envelope} alt="" />
              <a href="mailto:caaplanalytics.sales@careedge.in" className='contact-content'>caaplanalytics.sales@careedge.in</a>
            </div>
            <div className='mail-contact'>
              <img className='contact-icon' src={phone} alt="" />
              <a href="tel:+912261748900" className='contact-content'>+91 22 6174 8900</a>
            </div>
          </div>
          <div className="contact-form">
            <h2 className="form-title">Contact Us</h2>
            <form onSubmit={handleSubmit}>

              <div className="form-group">
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  placeholder=" " 
                  required 
                />
                <label htmlFor="name">Full Name*</label>
              </div>

              <div className="form-group">
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                  placeholder=" " 
                  required 
                />
                <label htmlFor="email">Email*</label>
              </div>

              <div className="form-group">
                <input 
                  type="tel" 
                  id="phone" 
                  name="phone" 
                  value={formData.phone} 
                  onChange={handleChange} 
                  placeholder=" " 
                  required 
                />
                <label htmlFor="phone">Mobile Number*</label>
              </div>

              <div className="form-group">
                <input 
                  type="text" 
                  id="company" 
                  name="company" 
                  value={formData.company} 
                  onChange={handleChange} 
                  placeholder=" " 
                  required 
                />
                <label htmlFor="company">Company Name*</label>
              </div>

              <div className="form-group">
                <textarea 
                  id="message" 
                  name="message" 
                  value={formData.message} 
                  onChange={handleChange} 
                  rows="3" 
                  placeholder=" " 
                  maxLength="300" 
                  required 
                ></textarea>
                <label htmlFor="message">Message*</label>
                <small className="message-counter">{formData.message.length}/300</small>
              </div>

              <div className="form-group captcha">
                <Captcha retoken={reCaptchaTokenHandler} />
              </div>

              <div className="form-group">
                <button type="submit" className="submit-button">Submit</button>
              </div>

            </form>
          </div>
        </div>
      
    </div>
  );
};

export default ContactForm;
