import React, { useEffect } from 'react';
import Select from 'react-select';
import './index.less';
import { useData, useCities, useSelectedCity, useProperties, useSelectedProperty } from '../../context/search-context';
import _ from 'lodash';
import service from '../../services/service';
import { useLocation } from 'react-router-dom';

const Searchbar = () => {
  const [data, setData] = useData();
  const [cities, setCities] = useCities();
  const [selectedCity, setSelectedCity] = useSelectedCity();
  const [properties, setProperties] = useProperties();
  const [selectedProperty, setSelectedProperty] = useSelectedProperty();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setSelectedCity(null);
      setSelectedProperty(null);
      // setProperties([]);
    }
  }, [location, setSelectedCity, setSelectedProperty, setProperties]);

  // useEffect(() => {
  //   setSelectedCity(null);
  //   setSelectedProperty(null);
  // }, [location, setSelectedCity, setSelectedProperty]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await service.fetchCities();
        if (_.isEmpty(response)) {
          throw new Error('HTTP error! status: No response');
        }
        setData(response);
        const cityOptions = response.map((location) => ({
          value: location.location,
          label: location.location,
        })).sort((a, b) => a.label.localeCompare(b.label));
        setCities(cityOptions);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [setData, setCities, location]);

  const handleCityChange = (selectedOption) => {
    setSelectedProperty(null);
    setSelectedCity(selectedOption);
    const selectedLocation = _.find(data, { location: selectedOption.value });
    const propertyOptions = selectedLocation.cities.map((i) => ({
      value: i.name,
      label: i.name,
    })).sort((a, b) => a.label.localeCompare(b.label));
    setProperties(propertyOptions);

    updateDynamicDropdownRows({
      cities: selectedCity,
      properties: selectedOption,
      propertiesOptions: properties
    });
  };

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);

    updateDynamicDropdownRows({
      cities: selectedCity,
      properties: selectedOption,
      propertiesOptions: properties
    });
  };

  const updateDynamicDropdownRows = (newRow) => {
    const existingRows = JSON.parse(localStorage.getItem('dynamicDropdownRows')) || [];
    if (existingRows.length > 0) {
      existingRows[0] = { 
        cities: newRow.cities || existingRows[0].cities, 
        properties: newRow.properties || existingRows[0].properties, 
        propertiesOptions: newRow.propertiesOptions || existingRows[0].propertiesOptions 
      };
    } else {
      existingRows.push(newRow);
    }
    localStorage.setItem('dynamicDropdownRows', JSON.stringify(existingRows));
  };

  return (
    <div className="cmp-search">
      <div className="cmp-search__container">
        <div className="cmp-search__container_searchbox">
          <div className="cmp-search__container_searchbox-mainfilter">
            <Select
              options={cities}
              value={selectedCity}
              onChange={handleCityChange}
              placeholder="Select City"
            />
          </div>
          <div className="cmp-search__container_searchbox-subfilter">
            <Select
              options={properties}
              onChange={handlePropertyChange}
              value={selectedProperty}
              isDisabled={!selectedCity}
              placeholder="Select Property"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
