import React, { useEffect, useState } from 'react';
import Login from './login';
import Logo from '../assets/careLogo.png';
import InfraLogo from '../assets/ReitsInfraEdge.svg';
import { useAuth } from '../context/auth-context';
import HamburgerMenu from './hamburger-menu';
//import service from '../services/service';
//import * as Cookies from 'js-cookie';

const Header = () => {
  const { user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const displayName = 'cmp-header';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    document.querySelector('.login-close')?.click();
  };

  // const handleClick = () => {
  //   window.location.href = '/contact';
  // }

  /*useEffect(() => {
    const fetchData = async () => {
      try {
        const response =  await service.getCsrfToken();
        if(response){
          Cookies.set('csrfToken', response)
        }
      } catch (error) {
        console.error('Error fetching csrfToken data:', error);
      }
    };
    fetchData();
  }, []);*/

  return (
    <div className={`${displayName}__container`}>
      <div className={`${displayName}__main`}>
        <div className={`${displayName}__logo`}>
          <a href='/'>
            <div className={`${displayName}__logo_container`}>
              <span>
                <img src={Logo} className={`${displayName}__logo_container--company`} alt='CARE EDGE'></img>
              </span>
              <span>
                <hr className={`${displayName}__logo_container--seprator`}></hr>
              </span>
              <span>
                <img src={InfraLogo} className={`${displayName}__logo_container--infra`} alt='REIT INFRA EDEG'></img>
              </span>
            </div>
          </a>
          <div className={`${displayName}__logo--title`}></div>
        </div>
        <div className={`${displayName}__search`}></div>
        <div className={`${displayName}__hamburger-menu`} onClick={toggleMenu}>
          <HamburgerMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} user={user} onLogOut={handleLogout} />
        </div>
        <div className={`${displayName}__login`}>
          {user ? <span className={`${displayName}__login--mail`}>{user.name}</span> : ''}
          <Login />
        </div>
      </div>
    </div>
  );
};

export default Header;
