import axios from 'axios';
//import * as Cookies from 'js-cookie';

const maxRetries = 5;
const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
});

// Request interceptor for adding token to headers and CSRF
axiosInstance.interceptors.request.use(
  async (config) => {
    /*
        const csrfToken = Cookies.get('csrfToken');
        if (csrfToken && config.method !== 'get') {
            config.headers['X-CSRF-TOKEN'] = csrfToken;
        }
        */
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor for handling token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    // Check if the csrfToken is sent as a cookie or header
    /*const csrfTokenFromHeaders = response.headers['X-CSRF-TOKEN'] || response.headers['CSRF-TOKEN'];
        if (csrfTokenFromHeaders) {
          console.log("CSRF Token from Headers: " + csrfTokenFromHeaders);
          Cookies.set('csrfToken', csrfTokenFromHeaders);
        }
        */
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest) {
      console.error('Original request is undefined:', error);
      return Promise.reject(error);
    }
    if (originalRequest && !originalRequest._retryCount) {
      originalRequest._retryCount = 0;
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post(`${process.env.API_URL}/auth/refresh-token`, {
          refreshToken: refreshToken,
        });
        const newToken = response.data.access_token;
        localStorage.setItem('access_token', newToken);

        const token = JSON.parse(localStorage.getItem('access_token'));
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        originalRequest.headers['Authorization'] = `Bearer ${token}`;

        return axiosInstance(originalRequest);
      } catch (e) {
        console.error('Token refresh failed:', e);
      }
    } else if (error.response && error.response.status === 429 && originalRequest._retryCount < maxRetries) {
      originalRequest._retryCount += 1; // Increment retry count
      console.warn(`Too Many Requests - Retrying attempt ${originalRequest._retryCount}...`);

      // Get retry delay from the 'Retry-After' header or default to 5 second
      const retryAfter = parseInt(error.response.headers['retry-after']) || 5;

      // Wait for the retry delay
      await new Promise((resolve) => setTimeout(resolve, retryAfter * 1000));

      // Retry the request
      return axiosInstance.request(originalRequest);
    }
    return Promise.reject(error);
  },
);
export default axiosInstance;
