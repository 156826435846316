import React from "react";
import './index.less';
import Login from "../login";

const HamburgerMenu = ({ isOpen, setIsOpen, user, onLogOut }) => {
    const displayName = 'cmp-hamburger';
    const toggleMenu = () => setIsOpen(!isOpen);
    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={`${displayName}`}>
            <div
                className={`${displayName}__container`}
                onClick={toggleMenu}
            >
                <div className={`${displayName}__container-burger burger1`}></div>
                <div className={`${displayName}__container-burger burger2`}></div>
                <div className={`${displayName}__container-burger burger3`}></div>
            </div>

            {isOpen && (
                <div
                    className={`${displayName}__content`}
                    onClick={handleContentClick}
                >
                    {user ? (
                        <>
                            <div className={`${displayName}__content-user`}>{user.name}</div>
                            <button
                                className={`${displayName}__content-logout`}
                                onClick={() => {
                                    onLogOut();
                                    toggleMenu();
                                }}
                            >
                                Logout
                            </button>
                        </>
                    ) : (
                        <div className={`${displayName}__content-login`}><Login/></div>
                    )}
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
