import React from 'react';
import { Outlet } from 'react-router-dom';
import ContactButton from '../../components/contact-button';

const Layout = () => {
  return (
    <div className="layout">
      <header className="layout-header">
      </header>
      <main className="layout-main">
        <Outlet />
      </main>
      <ContactButton />
    </div>
  );
};

export default Layout;
