import React, { useState, useEffect } from 'react';
import RangeSlider from './range-slider/index';

import './index.less'
import { useEvaluationState } from '../../../../../context/evaluation-context';
import Tooltip from '../../../../../shared/tool-tip';

const WACC = () => {
  const { evalState, setEvalState } = useEvaluationState();

  const [costOfDebt, setCostOfDebt] = useState(evalState.costOfDebt);
  const [costOfEquity, setCostOfEquity] = useState(evalState.costOfEquity);
  const [weightOfDebt, setWeightOfDebt] = useState(evalState.weightOfDebt);
  const [weightOfEquity, setWeightOfEquity] = useState(evalState.weightOfEquity);
  const [wacc, setWacc] = useState(evalState.waccRange);

  useEffect(() => {
    const totalWeight = weightOfDebt + weightOfEquity;
    if (totalWeight !== 100) {
      const adjustedWeightOfEquity = 100 - weightOfDebt;
      setWeightOfEquity(adjustedWeightOfEquity);
    }
  }, [weightOfDebt, weightOfEquity]);

  useEffect(() => {
    const calculatedWacc = (
      (costOfDebt * weightOfDebt + costOfEquity * weightOfEquity) / 100
    );
    setWacc(calculatedWacc);

    setEvalState((prevState) => ({
      ...prevState,
      costOfEquity: costOfEquity,
      costOfDebt: costOfDebt,
      weightOfEquity: weightOfEquity,
      weightOfDebt: weightOfDebt,
      waccRange: calculatedWacc,
    }));

  }, [costOfDebt, costOfEquity, setEvalState, weightOfDebt, weightOfEquity]);

  return (
    <div>
      <div className="wacc-container">
        <div>
          <RangeSlider
            label="Cost of Debt (%)"
            value={parseFloat(costOfDebt).toFixed(2)}
            min={2}
            max={10}
            step={0.1}
            onChange={(e) => setCostOfDebt(parseFloat(e.target.value))}
          />
          <RangeSlider
            label="Weight of Debt (%)"
            value={weightOfDebt}
            min={1}
            max={100}
            step={1}
            onChange={(e) => setWeightOfDebt(parseFloat(e.target.value))}
          />
          <RangeSlider
            label="Cost of Equity (%)"
            value={parseFloat(costOfEquity).toFixed(2)}
            min={8}
            max={17}
            step={0.1}
            onChange={(e) => setCostOfEquity(parseFloat(e.target.value))}
          />
          <RangeSlider
            label="Weight of Equity (%)"
            value={weightOfEquity}
            min={1}
            max={100}
            step={1}
            onChange={(e) => { setWeightOfEquity(parseFloat(e.target.value)); setWeightOfDebt(100 - parseFloat(e.target.value)) }}
          />
        </div>
      </div>
      <div className="wacc-display">
        <div className="wacc-display-head">
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", fontFamily: "Gotham-Bold" }}>
            <div>WACC</div>
            <div style={{ top: "-8px", position: 'relative' }}>
              {<Tooltip content={'An entity requires funds to support its growth, which it raises either in the form of debt or equity. The entity pays interest on the debt and gives a return to the equity holders. WACC combines both these costs (interest on debt and return to equity holders) into a single number that shows, the average cost of the funds raised.'} />}
            </div>
          </div>
        </div>
        <div className="wacc-display--v"> 
          <span className="wacc-display--v-value">{wacc.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</span>
        </div>
      </div>

    </div>
  );
};

export default WACC;
