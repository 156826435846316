import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactButton.less';
import { useLocation } from 'react-router-dom';

const ContactButton = () => {
  const displayName = 'cmp-contact-button';
  const navigate = useNavigate();
  const location = useLocation();

  if(location.pathname==='/contact'){
    return null;
  }

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <button className={`${displayName}`} onClick={handleContactClick}>
      Contact Us
    </button>
  );
};

export default ContactButton;
